<template>
	<div class="open-account-userinfo">
		<h1 class="h1_title">{{translate('choose_the_country')}}</h1>

		<div style="background: #fff;padding: 16px 16px 1px 16px">
			<div class="area" @click="call_face_verify">
				<van-image :src="require('@/assets/images/china.png')" width="100px"></van-image>
				<p>{{translate('chinese_mainland')}}</p>
			</div>

			<div class="area" @click="go_userinfo()">
				<van-image :src="require('@/assets/images/oversea.png')" width="100px"></van-image>
				<p>{{translate('overseas_users')}}</p>
			</div>
		</div>	
	</div>
</template>

<script>
	export default {
		name: 'account_area',
		data() {
			return {
				token: "",
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.token = this.$route.query.token
				
				this.init()
			}
		},
		methods: {
			init(){
				//根据TOKEN获取信息
				this.$axios({
					method: 'post',
					url: 'account_open/info',
					data: {
						token: this.token
					}
				}).then ((res) => {
					if (res.success) {
						
						if (res.data.status == 2) {
							//	待审核
							this.$router.replace('/account/success')
						} else if (res.data.status == 3) {
							//	不通过
							this.$router.replace('/account/fail?token=' + this.token)
						} else if (res.data.userinfo_data.length != 0) {
							if (res.data.userinfo_data.nationality != undefined) {
								this.$router.replace('/account/idcard?token=' + this.token)
							} else {
								this.$router.replace('/account/userinfo?token=' + this.token)
							}
						}
					} else {
						this.$toast.fail(res.error_text)
					}
				})
			},

			call_face_verify(){
                if (window.JsObject != null) {
                    window.JsObject.call_face_verify();
                }

                if (window.webkit != null) {
                    window.webkit.messageHandlers.jsToOcWithPrams.postMessage({"camera": "call_face_verify"});
                }
			},
			go_userinfo() {
				this.$router.push('/account/userinfo?token=' + this.token)
			}
		},
	}
</script>
<style scoped>
.area {
	text-align: center;
	margin-bottom: 15px;
	background: #f7f7f7;
	padding: 15px 0;
	border-radius: 5px;
}
</style>